import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { GTM_ID } from 'core/constants/general.constants';
import { updateJustLoggedIn } from 'core/store/auth';
import { authGetCustomerSelector, selectJustLoggedIn } from 'core/store/auth/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';
import gtmEvents from 'shared/utils/gtm-events';
import { initializeInsider, insertInsiderScript, setInsiderUser } from 'shared/utils/insider.utils';

const useInitializeEvents = () => {
  const customer = useAppSelector(authGetCustomerSelector);
  const customerId = customer?.id;
  const justLoggedIn = useAppSelector(selectJustLoggedIn);
  const priceListDescription = customer?.priceListDescription;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initializeGTM = () => {
      if (customer && GTM_ID) {
        TagManager.initialize({
          gtmId: GTM_ID,
        });
        gtmEvents.triggerInitializeUserEvent({
          documentNumber: customer.basicData.document.number,
          priceList: customer.priceList,
          externalDistributorName: customer.externalDistributorName,
          documentType: customer.basicData.document.type,
          department: customer.addresses[0]?.department ?? '',
          district: customer.addresses[0]?.district ?? '',
        });
      }
    };

    initializeGTM();
  }, [priceListDescription, customer]);

  useEffect(() => {
    const initializeInsiderUser = () => {
      if (customerId) {
        setInsiderUser(customer, customerId);
        insertInsiderScript();
        initializeInsider();
      }
    };

    initializeInsiderUser();
  }, [customerId, customer]);

  useEffect(() => {
    if (customer && justLoggedIn) {
      gtmEvents.triggerLoginSuccessEvent({
        document: customer.basicData.document.number,
        priceList: customer.priceList,
        externalDistributorName: customer.externalDistributorName,
        docType: customer.basicData.document.type,
      });
      dispatch(updateJustLoggedIn(false));
    }
  }, [customer, dispatch, justLoggedIn]);
};

export default useInitializeEvents;
