import { INSIDER_ID, INSIDER_URL } from 'core/constants/general.constants';
import { EInsiderPageType } from 'core/model/enums/insider.enum';
import { ICustomer } from 'core/model/interfaces/customer.interface';
import { IOrderCreated } from 'core/model/interfaces/order.interface';
import { ICartProduct, IProduct, IProductDetail } from 'core/model/interfaces/product.interface';
import { isLocalEnv } from './env.utils';
import {
  cartToInsiderCart,
  cutomerToInsiderUser,
  orderDetailToInsiderTransaction,
  productDetailToInsiderProduct,
  productListToInsiderProductList,
} from './insider.mapper';

const canRunInLocalEnv = isLocalEnv();

export const initializeInsider = () => {
  const { Insider } = window;

  if (Insider) {
    if (Insider.eventManager) {
      Insider.eventManager.dispatch?.('init-manager:re-initialize');
    } else {
      // eslint-disable-next-line no-console
      console.warn(`There is not eventManager in Insider`);
    }
  }

  // eslint-disable-next-line no-console
  else console.warn(`There is not Insider`);
};

export const settingInsiderPageType = (type: EInsiderPageType) => {
  window.insider_object = {
    ...window.insider_object,
    page: {
      type,
    },
  };

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log(`Actualización del Page Object en Insider:`, window.insider_object.page);
  }
};

export const cleanInsiderPageType = () => {
  window.insider_object = {
    ...window.insider_object,
    page: {},
  };
};

export const setInsiderUser = (customer: ICustomer, customerId: string) => {
  window.insider_object = {
    user: cutomerToInsiderUser(customer),
  };
  window.customer_id = customerId;
  localStorage.setItem('user_document_id', customer.basicData.document.number);

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del User Object en Insider:', window.insider_object.user);
  }
};

export const setInsiderProduct = (product: IProductDetail) => {
  window.insider_object = {
    ...window.insider_object,
    product: productDetailToInsiderProduct(product),
  };

  initializeInsider();

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del Product Object en Insider:', window.insider_object.product);
  }
};

export const setInsiderProductList = (products: Array<IProduct>) => {
  window.insider_object = {
    ...window.insider_object,
    listing: {
      items: productListToInsiderProductList(products),
    },
  };

  initializeInsider();

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del Listing Object en Insider:', window.insider_object.listing);
  }
};

export const setInsiderCart = (products: Array<ICartProduct>, currency: string, total: number) => {
  window.insider_object = {
    ...window.insider_object,
    basket: cartToInsiderCart(products, currency, total),
  };

  initializeInsider();

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del Basket Object en Insider:', window.insider_object.basket);
  }
};

export const setInsiderTransaction = (orders: Array<IOrderCreated>) => {
  window.insider_object = {
    ...window.insider_object,
    transaction: orderDetailToInsiderTransaction(orders),
  };

  initializeInsider();

  if (canRunInLocalEnv) {
    // eslint-disable-next-line no-console
    console.log('Actualización del Transaction Object en Insider:', window.insider_object.transaction);
  }
};

export const insertInsiderScript = (() => {
  let loader = false;
  return () => {
    if (!loader) {
      return new Promise(resolve => {
        loader = true;
        const url = `https://${INSIDER_URL}/ins.js?id=${INSIDER_ID}?v=${__APP_VERSION__}`;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        script.onload = () => {
          resolve(true);
        };
        document.body.appendChild(script);
      });
    }
  };
})();
